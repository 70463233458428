import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faCog,
  faComments,
  faGlobeAmericas,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, ContactFooter, Quote } from '../../components/UI';
import { Separator, BodyContainer } from '../../components/Layout';
import { servicesInfo } from '../../storage/services';
import './Services.css';

const Services = () => {
  const history = useHistory();

  // card click handler
  const onCardClickHandler = (serviceName) => {
    history.push(`/pricing/${serviceName}`);
  };

  const quoteData = {
    quote:
      'People do not care how much you know until they know how much you care.',
    author: 'Teddy Roosevelt',
  };

  useEffect(() => {
    document.title = 'Services | PrismaSofts';
  }, []);

  const header = (
    <div className="vertical-card-title">
      Our <span>Services</span>
    </div>
  );

  const cards = servicesInfo.map((service, index) => {
    const icon = [faCog, faGlobeAmericas, faShoppingCart, faComments];
    return (
      <Card
        type="services-card"
        key={service + index}
        clicked={() => onCardClickHandler(service.slug)}
      >
        <div className="icon-container">
          <FontAwesomeIcon icon={icon[index]} className="icon" />
        </div>
        <div className="service-card-right">
          <div className="service-name">{service.name}</div>
          <div className="service-info">{service.description}</div>
        </div>
      </Card>
    );
  });

  const body = <div className="services-card-container">{cards}</div>;

  return (
    <BodyContainer>
      <div style={{ paddingTop: '0.25rem' }}>
        <Quote data={quoteData} />
        <Separator />
      </div>
      {header}
      {body}
      <ContactFooter />
    </BodyContainer>
  );
};

export default Services;

import React, { useEffect } from 'react';
import logo from '../../assets/designer.svg';
import './WhyUs.css';
import { VerticalCards } from '../../components/UI';
import { Separator, BodyContainer } from '../../components/Layout';
import { cardsInfo } from '../../storage/cardsInfo';
import { HeroSection, ContactFooter } from '../../components/UI';

const WhyUs = () => {
  let hero_data = {
    header: 'What We Do',
    description:
      'From custom web applications to data-driven plaform, we enable businesses to launch and scale digitally. Keeping digital revolution at the forefront, we strive to provide small, medium as well as large businesses with the tools and techniques to make their analytics, logistics as well as customer management convenient.',
    importedImageSrc: logo,
  };

  useEffect(() => {
    document.title = 'Why Us | PrismaSofts';
  }, []);

  const landingCardInfo = cardsInfo.filter(
    (cardInfo) => cardInfo.page === 'whyus'
  )[0].cardInfo;
  return (
    <BodyContainer>
      <HeroSection data={hero_data} />
      <Separator />
      <div className="vertical-card-title">
        How We Are <span>Different</span>
      </div>
      <VerticalCards cardsInfo={landingCardInfo} />
      <Separator />
      <ContactFooter />
    </BodyContainer>
  );
};

export default WhyUs;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ScrollIndicator, Quote, Clients } from '../../components/UI';
import { Separator, BodyContainer } from '../../components/Layout';
import { cardsInfo } from '../../storage/cardsInfo';
import { motion } from 'framer-motion';
import vectorImage from '../../assets/world.svg';
import { VerticalCards } from '../../components/UI';
import './LandingPage.css';

const LandingPage = () => {
  const data = {
    description:
      'PrismaSofts is an emerging IT Company. At PrismaSofts, we care about our customers. We help businesses properly towards creating a digital experience of tomorrow, today. We are a bunch of individuals with aspirations to revolutionize the IT sector of our country, and of the whole world.',
  };

  const quoteData = {
    quote:
      'Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world, stimulating progress, giving birth to evolution.',
    author: 'Albert Einstein',
  };

  useEffect(() => {
    document.title = 'Home | PrismaSofts';
  }, []);

  const landingCardInfo = cardsInfo.filter(
    (cardInfo) => cardInfo.page === 'landing'
  )[0].cardInfo;

  return (
    <BodyContainer>
      <div className="landingpage-container">
        <div className="landingpage-information">
          <div className="landingpage-content">
            <h1 className="header">
              You <span>Invent,</span>
            </h1>
            <h1 className="header">
              We <span>Innovate.</span>
            </h1>
            <p className="description">{data.description}</p>
            <div className="cta-buttons">
              <Link to="/contact">
                <button className="request-a-quote">Request a Quote</button>
              </Link>
            </div>
          </div>
          <div className="vector_image">
            <motion.div animate={{ x: [50, 0] }}>
              <img src={vectorImage} alt="" max-width="600px"></img>
            </motion.div>
          </div>
        </div>

        <ScrollIndicator />
        <div className="vertical-card-title">
          Meet Prisma<span>Softs</span>
        </div>
        <VerticalCards cardsInfo={landingCardInfo} />

        <Separator />

        <Quote data={quoteData} />

        <Separator />

        <Clients />
      </div>
    </BodyContainer>
  );
};

export default LandingPage;

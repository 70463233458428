import React from 'react';
import { Link } from 'react-router-dom';
import { QuoteButton as Button } from '..';
import './Card.css';

const Card = (props) => {
  const cardHeader = !!props.title && (
    <>
      <div className="card-header">
        <div className="card-title">{props.title}</div>
        {props.titleInfo && (
          <div className="card-title-info">{props.titleInfo}</div>
        )}
      </div>
    </>
  );

  const cardClass = `card-body${
    props.cardType ? props.cardType.split(' ').join(' ') : ''
  }`;

  const cardBody = <div className={cardClass}>{props.children}</div>;

  const price = !!props.price && <div className="price">{props.price}</div>;

  const quote = !!props.quote && (
    <Link to="/contact">
      <Button label="Get a Quote" type="primary" />
    </Link>
  );

  const page = !!props.pages && (
    <div className="pages">Number of pages: {props.pages}</div>
  );

  return (
    <div
      className={`card ${props.type ? props.type : ''}`}
      onClick={props.clicked}
    >
      {cardHeader}
      {cardBody}
      {page}
      {price}
      {quote}
    </div>
  );
};

export default Card;

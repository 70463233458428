import React from 'react';
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import './PrismaFooter.css';

const PrismaFooter = () => {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-information">
          <div className="prisma-brand">
            <div className="prisma-logo">
              <img width={100} src={logo} alt="" />
            </div>
            <div className="prisma-social-links">
              <a
                href="https://www.facebook.com/PrismasoftAustralia"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="social-icon" />
              </a>
              <a
                href="https://www.facebook.com/PrismasoftAustralia/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="social-icon" />
              </a>
              <a
                href="https://www.instagram.com/prismasofts/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  fontSize="2rem"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
          <div className="prisma-navlinks">
            <h1>Quick Links</h1>
            <ul className="footer-navlinks">
              <NavLink exact to="/aboutus">
                About Us
              </NavLink>
              <NavLink exact to="/services">
                Services
              </NavLink>
              <NavLink exact to="/career">
                Careers
              </NavLink>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </ul>
          </div>
          <div className="prisma-information">
            <div className="prisma-info-container">
              <h1>Get In Touch</h1>
              <div className="prisma-information-location">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    fontSize: '1.5rem',
                    marginRight: '0.75rem',
                  }}
                />
                <div>
                  <p>19 Comber St NOBLE PARK VIC 3174</p>
                  <p>Shantinagar, Kathmandu, Nepal</p>
                  <p>Dargah Road, Hatigaon, Chariali</p>
                </div>
              </div>
              <div className="prisma-information-phone">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    fontSize: '1.5rem',
                    marginRight: '0.35rem',
                  }}
                />
                <a href="tel:+977-014115078">
                  <p>01-4115078</p>
                </a>
              </div>
              <div className="prisma-information-mail">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    fontSize: '1.5rem',
                    marginRight: '0.35rem',
                  }}
                />
                <p>contact@prismasofts.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2020, PrismaSofts Limited.</p>
      </div>
    </React.Fragment>
  );
};

export default PrismaFooter;

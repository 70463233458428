import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  LandingPage,
  Pricing,
  Comparison,
  NotFound,
  Services,
  ContactUs,
  Career,
  WhyUs,
  AboutUs,
} from './Pages';

const routesInfo = [
  { path: '/', component: LandingPage },
  {
    path: '/services',
    component: Services,
  },
  {
    path: '/pricing/:serviceSlug',
    component: Pricing,
  },
  {
    path: '/comparison/:packageSlug',
    component: Comparison,
  },
  {
    path: '/contact',
    component: ContactUs,
  },
  {
    path: '/career/:slug?',
    component: Career,
  },
  {
    path: '/whyus',
    component: WhyUs,
  },
  {
    path: '/aboutus',
    component: AboutUs,
  },
  {
    component: NotFound,
  },
];

const Routes = () => (
  <Switch>
    {routesInfo.map((route, index) => (
      <Route
        key={route + index}
        exact
        path={route.path}
        component={route.component}
      />
    ))}
  </Switch>
);

export default Routes;

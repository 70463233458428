import React, { useRef } from 'react';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ImageCard.css';
import { Avatar } from 'antd';

const ImageCard = ({ personInfo }) => {
  const imageSlideUp = useRef();

  const handleSlideUp = () => {
    imageSlideUp.current.classList.toggle('transform-team');
  };

  return personInfo.imageLink ? (
    <div className="image-card" onClick={handleSlideUp}>
      <div className="team-member-image">
        <img src={personInfo.imageLink} alt={`${personInfo.name}`} />
      </div>
      <div ref={imageSlideUp} className="team-member-description">
        <h2>{personInfo.name}</h2>
        <strong>{personInfo.designation}</strong>
        <p>{personInfo.motto}</p>
        <div className="team-social-media">
          <a target="_blank" rel="noreferrer" href={personInfo.links.facebook}>
            <FontAwesomeIcon icon={faFacebook} className="team-social-icon" />
          </a>
          <a target="_blank" rel="noreferrer" href={personInfo.links.instagram}>
            <FontAwesomeIcon
              icon={faInstagram}
              className="team-social-icon"
              href={personInfo.links.instagram}
            />
          </a>
          <a target="_blank" rel="noreferrer" href={personInfo.links.linkedin}>
            <FontAwesomeIcon
              icon={faLinkedin}
              className="team-social-icon"
              href={personInfo.links.linkedin}
            />
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div className="image-card" onClick={handleSlideUp}>
      <div className="team-member-image">
        <Avatar size={200} icon={<FontAwesomeIcon icon={faUser} />} />
      </div>
      <div className="team-member-description" ref={imageSlideUp}>
        <h2>{personInfo.name}</h2>
        <strong>{personInfo.designation}</strong>
        <p>{personInfo.motto}</p>
        <div className="team-social-media">
          <a target="_blank" rel="noreferrer" href={personInfo.links.facebook}>
            <FontAwesomeIcon icon={faFacebook} className="team-social-icon" />
          </a>
          <a target="_blank" rel="noreferrer" href={personInfo.links.instagram}>
            <FontAwesomeIcon
              icon={faInstagram}
              className="team-social-icon"
              href={personInfo.links.instagram}
            />
          </a>
          <a target="_blank" rel="noreferrer" href={personInfo.links.linkedin}>
            <FontAwesomeIcon
              icon={faLinkedin}
              className="team-social-icon"
              href={personInfo.links.linkedin}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;

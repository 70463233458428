import React, { useEffect, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { offices } from '../../../storage/officesData';
import 'mapbox-gl/dist/mapbox-gl.css';
import './LocationMap.css';

const LocationMap = ({ location }) => {
  const [viewport, setViewport] = useState({});

  useEffect(() => {
    setViewport({
      ...location,
      zoom: 14,
    });
  }, [location]);

  //* set the location markers
  const markers = offices.features.map((city, index) => (
    <Marker
      key={city.type + index}
      longitude={city.geometry.coordinates[0]}
      latitude={city.geometry.coordinates[1]}
      className="marker"
    />
  ));

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/prismasoft/ckj2g3wz61d1f19njb9jv57hj"
      width="95%"
      height="15rem"
      className="map-component"
      onViewportChange={setViewport}
    >
      {markers}
    </ReactMapGL>
  );
};

export default LocationMap;

import { useTable } from 'react-table';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const PlanComparisonTable = ({ columns, data }) => {
  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const TableHeader = () => {
    return (
      data && (
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
      )
    );
  };

  const TableBody = () => {
    return (
      data && (
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let className =
                    cell.row.original.id === 'packagePlan'
                      ? 'plan-header'
                      : 'plan-data';
                  if (
                    cell.value === '' &&
                    cell.row.original.id === 'packagePlan'
                  ) {
                    className += ' hidden';
                  }

                  //* conditional rendering of icons
                  if (cell.value === '✓') {
                    return (
                      <td {...cell.getCellProps()} className={className}>
                        {cell.render(
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{
                              fontSize: '1.25rem',
                              color: 'green',
                            }}
                          />
                        )}
                      </td>
                    );
                  } else if (cell.value === 'x') {
                    return (
                      <td {...cell.getCellProps()} className={className}>
                        {cell.render(
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            style={{
                              fontSize: '1.25rem',
                              color: 'red',
                            }}
                          />
                        )}
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()} className={className}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )
    );
  };

  const table = data && (
    <table {...getTableProps()}>
      <TableHeader {...headerGroups} />
      <TableBody />
    </table>
  );

  return table;
};

export default PlanComparisonTable;

export const packagesData = [
  {
    name: 'CMS Website',
    slug: 'cms-website',
    data: [
      {
        plan: 'Additional Pages $100',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Responsive Design',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Custom Layout Design',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'SEO Semantic HTML Code',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Main Banner Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'News Management (if applicable)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Event Calendar (if applicable)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },

      {
        plan: 'Testimonials Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },

      {
        plan: 'Newsletter',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Social Media Buttons',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Brochure Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Jobs Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'SEO Plugin',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google Location Map',
        starter: '✓',
        booster: '✓',
        premium: '',
      },
      {
        plan: 'XML SiteMap',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'URL Submission in SEs',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google Analytics',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Website Training',
        starter: 'Upto 2 Hrs',
        booster: 'Upto 3 Hrs',
        premium: 'Upto 5 Hrs',
      },
    ],
  },
  {
    name: 'Digital Marketing Packages',
    slug: 'digital-marketing-packages',
    data: [
      {
        plan: 'Campaign Setup/Ad Optimization',
        starter: '',
        booster: '',
        premium: '',
        id: 'packagePlan',
      },
      {
        plan: 'Initial Estimate Report',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Landing Page Recommendation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Unlimited Keywords',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Text Ads',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Conversion Tracking',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'A/B Testing',
        starter: 'x',
        booster: 'x',
        premium: '✓',
      },
      {
        plan: 'Google Analytics Set-up',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },

      {
        plan: 'Campaign Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },

      {
        plan: 'Mobile Ads',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Gmail Ads (GSP)',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Landing Page Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'ROI Tracking',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google & Bing Webmaster Tools',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google Analytics Integration',
        starter: '✓',
        booster: '✓',
        premium: '',
      },
      {
        plan: 'Check Mobile First Indexing Enabled',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Mobile Responsiveness Check',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'SSL Check & Recommendations Only',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'AMP Check',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: '404 Page Creation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'A/B Experiment Testing Check & Recommendations',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Heat Map Integration & Analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Conversion Setup',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Competition Analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'On-Page Optimization',
        id: 'packagePlan',
      },
      {
        plan: 'Canonicalization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Voice Search Optimization (AI Based)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Featured Snippet Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Content Optimization',
        starter: 'upto 30 pages',
        booster: 'upto 50 pages',
        premium: 'upto 100 pages',
      },
      {
        plan: 'Title/Meta Tags Optimization',
        starter: 'upto 30 pages',
        booster: 'upto 50 pages',
        premium: 'upto 100 pages',
      },
      {
        plan: 'Website Page Load Optimization (Desktop)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Pages H tags optimization',
        starter: 'upto 30 pages',
        booster: 'upto 50 pages',
        premium: 'upto 100 pages',
      },
      {
        plan: 'URL Structure optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Schema Mark-up Implementation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Robots.txt Creation/Analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Image & Hyperlink Optimization',
        starter: 'upto 30 pages',
        booster: 'upto 50 pages',
        premium: 'upto 100 pages',
      },
      {
        plan: 'HTML & XML Sitemap',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Internal Linking',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Title & Meta Tags Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Bing Webmaster Tools Set Up (if not set up)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'HTML Site Map Creation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Optimization of robots.txt & Google Bot Crawls',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Footer Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Website usability analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Check Header Status, Meta Robots, Google Cache of Pages',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'A/B Experiment Testing Check & Recommendations',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Check Mobility Issues',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Custom 404 Page',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'URL structure Analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Pagination Tags Analysis',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'No Follow on External Links',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Html Code Analysis of Target Pages',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Local Search Optimization',
        id: 'packagePlan',
      },
      {
        plan: 'Google My Business/Bing Local Listing',
        starter: '1 Location',
        booster: '2 Locations',
        premium: '5 Locations',
      },
      {
        plan: 'NAP Syndication',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Citation Building/Local Business Listing',
        starter: '5',
        booster: '7',
        premium: '10',
      },
      {
        plan: 'Classified Submissions',
        starter: '5',
        booster: '10',
        premium: '20',
      },
      {
        plan: 'Coupon Distribution',
        starter: '2',
        booster: '5',
        premium: '10',
      },
      {
        plan: 'Customer Review/Testimonial Submission',
        starter: '2',
        booster: '5',
        premium: '10',
      },
      {
        plan: 'Local Search Engine Submission',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Setting Geo Targeting in Google',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'KML Sitemap',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Contact Address in Footer',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google Adwords',
        id: 'packagePlan',
      },
      {
        plan: 'Monthly Ads Spend',
        starter: 'upto $200 (included)',
        booster: 'upto $400 (included)',
        premium: 'upto $600 (included)',
      },
      {
        plan: 'Landing Page Recommendation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Text Ads',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Conversion Tracking',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Campaign Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: "PLA's/Local Listing Ads",
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Landing Page Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google AdWords Management',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Google Adwords Monthly Report',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Social Media Marketing',
        id: 'packagePlan',
      },
      {
        plan: 'Social Profile Creation/Bookmarking',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Social Media Profile Optimization',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Facebook Account Setup',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Facebook Cover Image',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Facebook Timeline Design',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Facebook Ads Management',
        starter: 'upto $50 (included)',
        booster: 'upto $100 (included)',
        premium: 'upto $150 (included)',
      },
      {
        plan: 'Facebook Wall Updates',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Facebook Call to Action',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Targeted Page Likes',
        starter: '8 to 10%',
        booster: '10 to 12%',
        premium: '13%+',
      },
      {
        plan: 'LinkedIn Account (Personal Profile)',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'LinkedIn Profile Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'LinkedIn Company Page Creation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'LinkedIn Company Page Posting',
        starter: 'x',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'LinkedIn Company Page Images',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Social Media Optimization Report',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Online Reputation Management',
        id: 'packagePlan',
      },
      {
        plan: 'Phrases Targeted',
        starter: '1',
        booster: '3',
        premium: '5',
      },
      {
        plan: 'Search Positions Monitored',
        starter: '1 to 10',
        booster: '1 to 20',
        premium: '1 to 30',
      },
      {
        plan: 'Blog Posting',
        starter: '1',
        booster: '1',
        premium: '2',
      },
      {
        plan: 'Blog Promotion',
        starter: '1',
        booster: '3',
        premium: '5',
      },
      {
        plan: 'Company Profile Creation',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Social Bookmarking',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Article Posting',
        starter: '1',
        booster: '2',
        premium: '3',
      },
      {
        plan: 'Article Promotion',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Business Listing',
        starter: '5',
        booster: '7',
        premium: '10',
      },
      {
        plan: 'Customer Reviews/Ratings',
        starter: '3',
        booster: '5',
        premium: '10',
      },
      {
        plan: 'Development of Micro Sites',
        starter: '1',
        booster: '2',
        premium: '3',
      },
      {
        plan: 'Content Promotion (Company Profiles)',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Q&A Posting',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'ORM Monthly Report',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Content Marketing & Link Acquisition',
        id: 'packagePlan',
      },
      {
        plan: 'Blog Writing',
        starter: '1',
        booster: '2',
        premium: '3',
      },
      {
        plan: 'Web 2.0 Profile Creation',
        starter: '5',
        booster: '10',
        premium: '15',
      },
      {
        plan: 'Guest Blog posting',
        starter: '1',
        booster: '2',
        premium: '2',
      },
      {
        plan: 'Guest Blog Social Bookmarking',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Forum Posting',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Informational Article Writing & Submission',
        starter: '1',
        booster: '2',
        premium: '3',
      },
      {
        plan: 'Article Social Bookmarking',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Press Release Writing & Distribution',
        starter: 'x',
        booster: '1',
        premium: '1',
      },
      {
        plan: 'Premium PR Distribution',
        starter: 'x',
        booster: 'x',
        premium: '✓',
      },
      {
        plan: 'DOC/PDF Submission',
        starter: '1',
        booster: '1',
        premium: '2',
      },
      {
        plan: 'PPT Creation + Distribution',
        starter: '1',
        booster: '1',
        premium: '2',
      },
      {
        plan: 'Image / Pinterest Submission',
        starter: '1',
        booster: '5',
        premium: '8',
      },
      {
        plan: 'Infographic Creation & Distribution',
        starter: '1',
        booster: '2',
        premium: '2',
      },
      {
        plan: 'Video Marketing',
        id: 'packagePlan',
      },
      {
        plan: 'Phrases Targeted',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Keyword Research, YouTube Channel Setup/Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Podcast Creations',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Podcast Submissions',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'YouTube Channel Creation',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'YouTube Keyword Research',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Video Title/Description Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Video Tags/Channel Tags Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Embedding Videos in Articles/Blogs',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Social Sharing of Videos',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Embedding/Sharing Videos on Q&A',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Video Submissions',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Video Marketing Monthly Report',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog Optimization',
        id: 'packagePlan',
      },
      {
        plan: 'External Blog Setup',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog Optimization',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog RSS Feed Submission',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog Directory Submission',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
      {
        plan: 'Blog Social media integration',
        starter: '✓',
        booster: '✓',
        premium: '✓',
      },
    ],
  },
  {
    name: 'E-Commerce Website',
    slug: 'e-commerce-website',
    data: [
      {
        plan: 'Custom Layout Design',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Theme Integration',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'JQUERY Slider Home Page',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Search',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Advanced Search',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Signin, Signup, Forget Password',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'My Account',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Wishlist',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },

      {
        plan: 'Shopping Cart',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },

      {
        plan: 'Product Listing',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Product Detail Page',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Product Comparison',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Multiple Product Image',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Write a Review',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Email to Friend',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Share on social sites (pinit, fb, twitter, linkedIn',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Similar Products',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Latest Arrivals',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Best Sellers',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Guest Checkout',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Single Page Checkout',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Discount Coupon',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Abandon Cart',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Payment Gateway Integration (Paypal)',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Shipping Methods',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Testimonials',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Newsletter',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'FAQ',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Contact Us',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Sitemap',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Static HTML URLs',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Live Chat',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Customer Feedback',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Different Price for Public and Retailers',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Multiple Currency Support',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Multiple Language Support',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
      {
        plan: 'Blog',
        wordpress: '✓',
        opencart: '✓',
        javascript: '✓',
        custom: '✓',
      },
    ],
  },
];

export const packageColumns = [
  {
    name: 'CMS Website',
    data: [
      {
        Header: '',
        accessor: 'plan',
      },
      {
        Header: 'Starter',
        accessor: 'starter',
      },
      {
        Header: 'Booster',
        accessor: 'booster',
      },
      {
        Header: 'Premium',
        accessor: 'premium',
      },
    ],
  },
  {
    name: 'Digital Marketing Packages',
    data: [
      {
        Header: '',
        accessor: 'plan',
      },
      {
        Header: 'Starter',
        accessor: 'starter',
      },
      {
        Header: 'Booster',
        accessor: 'booster',
      },
      {
        Header: 'Premium',
        accessor: 'premium',
      },
    ],
  },
  {
    name: 'E-Commerce Website',
    data: [
      {
        Header: '',
        accessor: 'plan',
      },
      {
        Header: 'WordPress',
        accessor: 'wordpress',
      },
      {
        Header: 'OpenCart',
        accessor: 'opencart',
      },
      {
        Header: 'JavaScript',
        accessor: 'javascript',
      },
      {
        Header: 'Custom',
        accessor: 'custom',
      },
    ],
  },
];

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../index';
import './ContactFooter.css';

const ContactFooter = () => {
  const history = useHistory();

  return (
    <div className="contact-us-container">
      <div className="title">
        Want to <span className="highlight">Grow With Us</span>?
      </div>
      <div className="contact-us-btn">
        <Button
          type="primary-solid contact-us"
          label="Contact Us"
          hasIcon
          clicked={() => history.push('/contact')}
        />
      </div>
    </div>
  );
};

export default ContactFooter;

import React, { useState, useRef, useEffect } from 'react';
import './ModalForm.css';
import { Modal, Form, Input, Button, Select, Spin } from 'antd';
import axios from 'axios';
import toast from 'react-hot-toast';

function ModalForm({ visible, closeModal, selectedVacancy, vacancies }) {
  const [fileList, setFileList] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [fileInputKey, setFileInputKey] = useState('');

  const fileRef = useRef();

  const [form] = Form.useForm();

  const { TextArea } = Input;

  useEffect(() => {
    let selectedVacancyLabel = selectedVacancy.jobTitle;
    if (selectedVacancy.level) {
      selectedVacancyLabel += ' - ' + selectedVacancy.level;
    }
    form.setFieldsValue({
      position: selectedVacancyLabel,
    });
  }, [form, selectedVacancy]);

  const resetFileInputKey = () => {
    let randomString = Math.random().toString(36);

    setFileInputKey(randomString);
  };

  const handleUpload = (e) => {
    if (
      ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(
        e.target.files[0].name.split('.').pop()
      )
    ) {
      setFileList(e.target.files[0]);
    } else {
      toast.error('Please select valid file format');
      resetFileInputKey();
    }
  };

  const onFinish = (values) => {
    setSubmitting(true);

    const formdata = new FormData();
    formdata.append('fullname', values.fullname);
    formdata.append('email', values.email);
    formdata.append('position', values.position);
    formdata.append('contactNumber', values.contact);
    formdata.append('cv', fileList);
    formdata.append('coverLetter', values.coverLetter);

    axios({
      method: 'post',
      url: 'https://prismasoft-admin.herokuapp.com/api/career',
      data: formdata,
      headers: { 'Content-Type': 'multi-part/form-data' },
    })
      .then(() => {
        toast.success('Message submitted successfully.');
        form.resetFields();
      })
      .catch((error) => {
        toast.error('Application submission failed');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      title="Apply Now"
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      key={selectedVacancy.jobId}
    >
      <Form
        name={selectedVacancy.jobId}
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: 'Please enter your name!',
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select the position you are applying for',
            },
          ]}
          name="position"
        >
          <Select placeholder="Which position are you applying for?">
            {vacancies.map((vacancy) => (
              <Select.Option
                value={
                  vacancy.level
                    ? vacancy.jobTitle + ' - ' + vacancy.level
                    : vacancy.jobTitle
                }
                key={vacancy.jobId}
              >
                {vacancy.level
                  ? vacancy.jobTitle + ' - ' + vacancy.level
                  : vacancy.jobTitle}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="contact"
          rules={[
            {
              required: true,
              message: 'Please input your contact number!',
            },
          ]}
        >
          <Input placeholder="Contact number" />
        </Form.Item>
        <Form.Item label="Upload your CV">
          <Input
            type="file"
            name="cv"
            ref={fileRef}
            key={fileInputKey}
            onChange={handleUpload}
            accept=".jpg, .jpeg, .doc, .docx, .png"
            required
          />
          We accept PDF, DOC, DOCX, JPG and PNG files.
        </Form.Item>
        <Form.Item name="coverLetter">
          <TextArea placeholder="Let's hear more about you" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitting ? 'Submitting' : 'Submit'}
          </Button>
          {submitting ? <Spin /> : null}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalForm;

import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './MessageUs.css';
import toast from 'react-hot-toast';

const ContactForm = (props) => {
  let state = props.state;

  const [submitState, setSubmitState] = useState('notSubmitted');

  //* handle the form data
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      name: event.target[0].value,
      email: event.target[2].value,
      interest: event.target[1].value,
      message: event.target[3].value,
    };
    let apiData = JSON.stringify(data);

    // post to api here...
    axios({
      method: 'post',
      url: 'https://prismamessage.herokuapp.com/',
      data: apiData,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        toast.success('Message submitted successfully.');
      })
      .catch((error) => {
        toast.error(error.message);
      });

    props.handleCloseAction();
  };

  if (state === 'Clicked') {
    return (
      <div>
        <div id="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="close-btn" onClick={props.handleCloseAction}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ color: 'var(--text-primary)' }}
              />
            </div>
            <div className="message-form-item">
              <label>Full Name </label>
              <input className="input_box" type="text" name="name" />
            </div>
            <div className="message-form-item">
              <label>Interest</label>
              <select
                defaultValue="software_development"
                className="input_box"
                name="interest"
              >
                <option value="software_development">
                  Software Development
                </option>
                <option value="web_development">Web Development</option>
                <option value="digital_marketing">Digital Marketing</option>
                <option value="engineering_consulting">
                  Engineering Consulting
                </option>
              </select>
            </div>
            <div className="message-form-item">
              <label>Email</label>
              <input className="input_box" type="email" name="email" />
            </div>
            <div className="message-form-item">
              <label>Message</label>
              <textarea className="input_box" type="text" name="message" />
            </div>
            <input
              className="submit_button"
              type="submit"
              value="Submit"
              onClick={() => {
                if (submitState === 'notSubmitted') {
                  setSubmitState('Submitted');
                }
              }}
            />
          </form>
        </div>
      </div>
    );
  } else {
    if (submitState === 'Submitted') {
      setSubmitState('notSubmitted');
    }
    return null;
  }
};

export default ContactForm;

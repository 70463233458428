import React from 'react';
import './QuoteButton.css';

const QuoteButton = ({ label, type, style }) => {
  return (
    <div className={`quote-btn ${type}`} style={style}>
      {label}
    </div>
  );
};

export default QuoteButton;

import React from 'react';
import { FacebookShareButton, FacebookIcon } from 'react-share';

import './FacebookShareIcon.css';

const FacebookShareIcon = (props) => {
  return (
    <FacebookShareButton
      url={props.url}
      hashtag={props.hashtag}
      className="facebook-share"
    >
      <FacebookIcon size={36} round={true} />
    </FacebookShareButton>
  );
};

export default FacebookShareIcon;

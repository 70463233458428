export const offices = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [145.1634269, -37.9627356],
      },
      properties: {
        id: 0,
        location: 'Australia',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [91.780921, 26.128703],
      },
      properties: {
        id: 1,
        location: 'India',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [85.3442163, 27.6901631],
      },
      properties: {
        id: 2,
        location: 'Nepal',
      },
    },
  ],
};

import React from 'react';
import Routes from './routes';
import { Toaster } from 'react-hot-toast';
import { Footer, NavigationBar, MessageUs } from './components/UI';
import ScrollToTop from './utils/ScrollToTop';
import './App.css';

function App() {
  return (
    <React.Fragment>
      <div>
        <Toaster />
      </div>
      <ScrollToTop />
      <NavigationBar />
      <div className="App">
        <Routes />
      </div>
      <Footer />
      <MessageUs />
    </React.Fragment>
  );
}

export default App;

import React, { useEffect } from 'react';
import './AboutUs.css';
import logo from '../../assets/group.svg';
import { Quote, HeroSection, OurTeam } from '../../components/UI';
import { Separator, BodyContainer } from '../../components/Layout';

const AboutUs = () => {
  let data = {
    quote:
      'The Web as I envisaged it, we have not seen it yet. The future is still so much bigger than the past.',
    author: 'Tim Berners Lee',
  };
  let hero_data = {
    header: 'About Us',
    description:
      'We strive to create perfection in everything that we do. Our vision of a digital future where imagination is the only limitation. We are the living embodiment of struggle and passion. We are a bunch of individuals with aspirations to conquer the world.',
    importedImageSrc: logo,
  };

  useEffect(() => {
    document.title = 'About Us | PrismaSofts';
  }, []);

  return (
    <BodyContainer>
      <HeroSection data={hero_data} />

      <Separator />
      <div className="about_quote">
        <Quote data={data} />
      </div>

      <Separator />

      <div className="meetPrismaSofts">
        <h2 className="meetPrismaSofts_header">
          Meet <span>PrismaSoftians</span>
        </h2>
        <h3 className="meetPrismaSofts_subheader">
          "None of us is as smart as all of us."
        </h3>
        <br />
        <div></div>
      </div>
      <OurTeam />
    </BodyContainer>
  );
};

export default AboutUs;

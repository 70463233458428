import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { offices } from '../../../storage/officesData';
import { getNearestOfficeCoords } from '../../../utils/getNearestOffice';
import { Map } from '../index';
import './OfficeLocation.css';

const OfficeLocation = () => {
  const [curLocation, setCurLocation] = useState({});
  const [activeId, setActiveId] = useState(0);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const nearestPos = getNearestOfficeCoords(position, offices);
          setCurLocation({
            latitude: nearestPos.geometry.coordinates[1],
            longitude: nearestPos.geometry.coordinates[0],
          });
          setActiveId(nearestPos.properties.id);
        },
        () => {
          toast.error('Please allow location for best experience.', {
            duration: 2000,
          });
          setCurLocation({
            latitude: offices.features[0].geometry.coordinates[1],
            longitude: offices.features[0].geometry.coordinates[0],
          });
          setActiveId(0);
        }
      );
    }
  }, [setCurLocation, setActiveId]);

  const changeLocation = (e, id) => {
    setCurLocation({
      latitude: offices.features[id].geometry.coordinates[1],
      longitude: offices.features[id].geometry.coordinates[0],
    });
    setActiveId(id);
  };

  return (
    <div className="office-container">
      <h1>Offices</h1>
      <div className="office-information">
        <div
          className={`australia-office ${
            activeId === 0 ? 'active-office' : ''
          }`}
          id="office-1"
          onClick={(event) => changeLocation(event, 0)}
        >
          <h2>Australia</h2>
          <p>Suite 5.2, 1 Palmerston Grove, Oakleigh, VIC-3166</p>
        </div>
        <div
          className={`india-office ${activeId === 1 ? 'active-office' : ''}`}
          id="office-2"
          onClick={(event) => changeLocation(event, 1)}
        >
          <h2>India</h2>
          <p>Dargah Road, Hatigaon Chariali</p>
        </div>
        <div
          className={`nepal-office ${activeId === 2 ? 'active-office' : ''}`}
          id="office-3"
          onClick={(event) => changeLocation(event, 2)}
        >
          <h2>Nepal</h2>
          <p>Shantinagar, Kathmandu</p>
        </div>
      </div>
      <Map location={curLocation} />
    </div>
  );
};

export default OfficeLocation;

import React, { useEffect, useCallback, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PlanComparisonTable } from '../../components/UI';
import { packageColumns, packagesData } from '../../storage/subPackageData';
import { BodyContainer } from '../../components/Layout';
import { motion } from 'framer-motion';
import './Comparison.css';

const Comparison = () => {
  const { pathName } = useLocation();
  const { packageSlug } = useParams();
  const history = useHistory();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = 'Comparison | PrismaSofts';
  }, []);

  const populateData = useCallback(() => {
    //# get the package info
    let servicename = packagesData.find((pckg) => pckg.slug === packageSlug);
    //# redirect on incorrect route
    if (!servicename) {
      history.push('/404');
    } else {
      servicename = servicename.name;
      // //# import packages info from a mock file
      const dataTemp = packagesData.filter(
        (service) => service.name === servicename
      )[0];
      setData(dataTemp.data);

      const colTemp = packageColumns.filter(
        (service) => service.name === servicename
      )[0];
      setColumns(colTemp.data);
    }
  }, [history, packageSlug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    populateData();
  }, [pathName, populateData]);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  let table = null;
  if (data) {
    table = <PlanComparisonTable columns={columns} data={data} />;
  }

  return (
    <React.Fragment>
      <BodyContainer>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="comparison-wrapper"
          transition={{ delay: 0.3 }}
        >
          <div className="header">
            <div className="header-title">Comparison Chart</div>
            <div className="header-subtitle">
              Select the plan that better suits you.
            </div>
          </div>
          <div className="table-container">{table}</div>
          <div className="pricing-link-container">
            <Link to="/services" className="pricing-link">
              Goto Services Page
            </Link>
          </div>
        </motion.div>
      </BodyContainer>
    </React.Fragment>
  );
};

export default Comparison;

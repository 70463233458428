import React from 'react';
import { teamInfo } from '../../../storage/teamsInfo.js';
import { ImageCard } from '../ImageCard';
import { rajendra } from '../../../assets/teamImages';
import './OurTeam.css';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OurTeam = () => {
  return (
    <div className="team-container">
      <div className="prisma-executive-officer">
        <img src={rajendra} alt="" />
        <div className="prisma-executive-officer-information">
          <div className="primsa-executive-officer-name">
            <h1>Rajendra Chataut</h1>
          </div>
          <div className="primsa-executive-officer-designation">
            <h2>Executive Chairman</h2>
            <h2>Prisma Group</h2>
            <h2>CEO, PrismaSofts Global</h2>
          </div>
          <div className="primsa-executive-officer-quickLinks">
            <a
              href="https://www.facebook.com/PrismasoftAustralia/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            </a>
            <a
              href="https://www.facebook.com/PrismasoftAustralia/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/rajendrachataut/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                fontSize="2rem"
                className="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="team-information">
        {teamInfo.map((memberInfo, index) => (
          <ImageCard key={memberInfo.name + index} personInfo={memberInfo} />
        ))}
      </div>
    </div>
  );
};

export default OurTeam;

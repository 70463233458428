export const getNearestOfficeCoords = (position, FeatureCollection) => {
  const lat1 = position.coords.latitude;
  const lng1 = position.coords.longitude;

  const radLat1 = (Math.PI * lat1) / 180;
  const nauticalMileToMile = Math.PI * 60 * 1.1515;

  let lowestValue = 12451; // Earth average longest semicircle
  let closestPlace = null;

  for (const i in FeatureCollection.features) {
    const thisFeature = FeatureCollection.features[i];

    const lng2 = thisFeature.geometry.coordinates[0];
    const lat2 = thisFeature.geometry.coordinates[1];
    const placeMeta = thisFeature;

    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lng1 - lng2;
    const radTheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radLat1) * Math.sin(radLat2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = (Math.acos(dist) * 180) / nauticalMileToMile;

    if (dist < lowestValue) {
      lowestValue = dist;
      closestPlace = placeMeta;
    }
  }
  return closestPlace;
};

import React from 'react';
import leftquote from '../../../assets/left-quote.svg';
import './Quote.css';

const Quote = ({ data }) => {
  return (
    <div className="quote-component">
      <div className="quote-information">
        <div className="quote-icon">
          <img src={leftquote} alt="quote-left" />
        </div>
        <div className="quote">“{data.quote}”</div>
        <div className="writer">- {data.author}</div>
      </div>
    </div>
  );
};

export default Quote;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { Button, Collapse } from 'antd';
import { vacancies } from '../../../storage/vacancies';
import { Modal } from '../index';
import './Vacancy.css';

import FacebookShareIcon from '../FacebookShareIcon/FacebookShareIcon';

const { Panel } = Collapse;

const Vacancy = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVacancy, setSelectedVacancy] = useState({});

  const { slug } = useParams();

  const history = useHistory();

  let currentVacancy = {};
  let filteredVacancy = [];

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById(slug)) {
        document.getElementById(slug).scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  });

  if (slug) {
    filteredVacancy = vacancies.filter((vacancy) => vacancy.slug === slug);

    if (filteredVacancy.length) {
      currentVacancy = filteredVacancy[0];
    } else {
      return <Redirect to="/career" />;
    }
  }

  const showModal = (vacancy) => {
    setSelectedVacancy(vacancy);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const updataVacancyUrl = (key) => {
    history.replace({ pathname: `/career/${key}` });
  };

  return (
    <div className="accordion-container">
      {vacancies.length > 0 ? (
        <Collapse
          accordion
          className="accordion"
          defaultActiveKey={'slug' in currentVacancy ? currentVacancy.slug : ''}
          onChange={updataVacancyUrl}
        >
          {vacancies.map((vacancy) => (
            <Panel
              header={
                vacancy.jobTitle +
                ' : ' +
                vacancy.level +
                ' (' +
                vacancy.openings +
                ')'
              }
              key={vacancy.slug}
              style={{ fontSize: '1.15rem' }}
              id={vacancy.slug}
            >
              <div className="skills-and-qualification">
                <h1>Skills and Qualifications:</h1>
                <ul className="skills-list">
                  {vacancy.skills_and_qualifications?.map((skills, index) => (
                    <li key={index + 1}>{skills.title}</li>
                  ))}
                </ul>
              </div>
              <div className="job-description">
                <h1>Job Description:</h1>
                <ul className="description-list">
                  {vacancy.job_description?.map((job, index) => (
                    <li key={index + 2}>{job.title}</li>
                  ))}
                </ul>
              </div>
              <div className="deadline">
                <strong>
                  Deadline: {moment(vacancy.deadline).format('LL')}
                </strong>
              </div>
              <div className="cta-apply-now">
                <Button
                  size="large"
                  onClick={() => showModal(vacancy)}
                  type="primary"
                  style={{
                    background: 'var(--blue)',
                    borderColor: 'var(--blue)',
                    borderRadius: '8px',
                  }}
                >
                  Apply Now
                </Button>
                <FacebookShareIcon
                  url={window.location.href + '/' + vacancy.slug}
                  hashtag="prismasofts"
                />
              </div>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <div className="no-vacancy">
          <h1>Sorry, there are no any current openings.</h1>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        closeModal={closeModal}
        selectedVacancy={selectedVacancy}
        vacancies={vacancies}
      />
    </div>
  );
};

export default Vacancy;

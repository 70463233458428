import React, { useEffect } from 'react';
import './ContactUs.css';
import { Offices, ContactForm } from '../../components/UI';
import { BodyContainer } from '../../components/Layout';

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact Us | PrismaSofts';
  });

  return (
    <BodyContainer>
      <div className="contact-container">
        <div className="contact-information">
          <div className="headline">
            <h1>
              Let's <span className="headline-sub">work</span> together
            </h1>
          </div>
          <div className="contact-body">
            <Offices />
            <ContactForm />
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};

export default ContactUs;

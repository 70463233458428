export const servicesInfo = [
  {
    name: 'Mobile Application Development',
    slug: 'mobile-application-development',
    description:
      "We build softwares for enterprises and other IT, IoT or Smart Systems for Local Government's.",
  },
  {
    name: 'Web Development',
    slug: 'web-development',
    description:
      'We develop different simple to complex websites based on different technologies along with web hosting.',
  },
  {
    name: 'Digital Marketing',
    slug: 'digital-marketing',
    description:
      "We are always ready to take businesses to next level through digital marketing's.",
  },
  {
    name: 'Engineering Consulting',
    slug: 'engineering-consulting',
    description:
      "We offer engineering and other consulting facility to our client in all major spectrum of technologies's.",
  },
];

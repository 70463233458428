import React, { useState } from 'react';
import './MessageUs.css';
import logo from '../../../assets/messenger.svg';
import ContactForm from './MessageForm';

const MessageUs = () => {
  const [clickState, setClickState] = useState('unClicked');
  const [class_name, setClassName] = useState('contact_image');

  const onCloseHandler = () => {
    setClickState('unClicked');
    setClassName('contact_image visible-image');
  };

  return (
    <div className="messageus">
      <ContactForm state={clickState} handleCloseAction={onCloseHandler} />
      <div
        className={class_name}
        onClick={() => {
          if (clickState === 'unClicked') {
            setClickState('Clicked');
            setClassName('contact_image hidden-image');
          } else {
            setClickState('unClicked');
            setClassName('contact_image visible-image');
          }
        }}
      >
        <img src={logo} alt="messenger-icon" className="messenger-icon"></img>
      </div>
    </div>
  );
};

export default MessageUs;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VerticalCard.css';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="vertical-card">
      <FontAwesomeIcon icon={icon} className="vertical-card-icon" />
      <div className="card-title">{title}</div>
      <div className="card-body">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;

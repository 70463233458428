import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './ScrollIndicator.css';

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator">
      <motion.div
        animate={{ y: [-5, 5, -5] }}
        transition={{ repeat: Infinity, duration: 1 }}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ color: '#bbb', fontSize: '2.5rem' }}
        />
      </motion.div>
    </div>
  );
};

export default ScrollIndicator;

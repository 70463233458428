import React from 'react';
import './HeroSection.css';

/* data format
const data = {
  header: "You Invent, We Innovate.",
  description:
    "At PrismaSoft, we care about our customers. We help businesses propel towards creating a digital experience of tomorrow, today.",
  importedImageSrc: { imgsrc },
  buttons: [
    {
      name: "Request a Quote",
      link: "",
    },
    {
      name: "Request a Quote",
      link: "",
    },
  ],
};
 */

const HeroSection = (props) => {
  let data = props.data;

  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1 className="hero-header">{data.header}</h1>
        <p className="description">{data.description}</p>
      </div>
      <img
        className="hero-image"
        alt={data.header}
        src={data.importedImageSrc}
      ></img>
    </div>
  );
};

export default HeroSection;

import React, { useEffect } from 'react';
import './Career.css';
import { Vacancy } from '../../components/UI/';
import { Separator } from '../../components/Layout';

const Career = () => {
  // when the page loads, change the title
  useEffect(() => {
    document.title = 'Career | PrismaSofts';
  }, []);

  return (
    <div className="career-container">
      <div className="career-information">
        <h1>Work at PrismaSoft</h1>
        <h2>
          We're working to find new and better ways to help businesses succeed.
        </h2>
        <Separator />
        <div className="current-openings">Current Openings</div>
        <div className="carrer-vacancies"></div>
      </div>
      <Vacancy />
    </div>
  );
};

export default Career;

import React from 'react';
import { VerticalCard } from './VerticalCard';
import './VerticalCards.css';

const Features = ({ cardsInfo }) => {
  return (
    <div className="vertical-cards-container">
      <div className="vertical-card-information">
        <div className="vertical-card-cards">
          {cardsInfo.map(({ icon, title, description }, index) => {
            return (
              <VerticalCard
                key={title + index}
                icon={icon}
                title={title}
                description={description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;

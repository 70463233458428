import React, { useRef, useState, useEffect } from 'react';
import './NavigationBar.css';
import { MenuItems } from './Menu';
import { ScrollMenu } from './ScrollBehavior';
import { NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import logo from '../../../assets/logo.svg';

const { SubMenu } = Menu;

const PrismaNavigation = () => {
  const burgerMenu = useRef();
  const sideBar = useRef();

  const [changeNav, setChangeNav] = useState(0);

  const toggleSideBar = () => {
    if (
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) <= 768
    ) {
      sideBar.current.classList.toggle('toggleSidebar');
      burgerMenu.current.classList.toggle('toggle');
    }
  };

  const changeNavPosition = () => {
    const scrollPos = document.body.getBoundingClientRect().top;
    setChangeNav(scrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavPosition);
  }, [changeNav]);

  const menu = (
    <Menu>
      {MenuItems?.map((items) =>
        items.navlinks.map((navItems) =>
          navItems.dropDownData.map((ddMenu) => (
            <Menu.Item key={ddMenu.label}>
              <NavLink
                onClick={toggleSideBar}
                activeClassName="activeNavlink"
                exact
                to={ddMenu.link}
              >
                {ddMenu.label}
              </NavLink>
            </Menu.Item>
          ))
        )
      )}
      <SubMenu title="More">
        {MenuItems.map((items) =>
          items.navlinks.map((navItems) =>
            navItems.hasDropDown ? (
              navItems.dropDownData.map((ddmenu) =>
                ddmenu.hasDropDown ? (
                  ddmenu.subDropDownData.map((subDdMenu) => (
                    <Menu.Item key={subDdMenu.label}>
                      <NavLink
                        onClick={toggleSideBar}
                        activeClassName="activeNavlink"
                        exact
                        to={subDdMenu.link}
                      >
                        {subDdMenu.label}
                      </NavLink>
                    </Menu.Item>
                  ))
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )
          )
        )}
      </SubMenu>
    </Menu>
  );

  return (
    <div
      className={`${
        changeNav < 0 && ScrollMenu.scrollBehavior
          ? 'navigation-bar scrollNavBar'
          : 'navigation-bar'
      }`}
    >
      <nav>
        <div className="logo">
          <NavLink to="/">
            <img width={50} src={logo} alt="" />
          </NavLink>
        </div>
        <ul ref={sideBar} className="navlinks">
          {MenuItems?.map((items) =>
            items.navlinks.map((item) =>
              !item.hasDropDown ? (
                <NavLink
                  onClick={toggleSideBar}
                  activeClassName="activeNavlink"
                  key={item.label}
                  exact
                  to={item.link}
                >
                  {item.label}
                </NavLink>
              ) : (
                <Dropdown key={item.label} overlay={menu}>
                  <NavLink key={item.label} to="">
                    {item.label} <DownOutlined />
                  </NavLink>
                </Dropdown>
              )
            )
          )}
        </ul>
        <div ref={burgerMenu} onClick={toggleSideBar} className="burger-menu">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  );
};

export default PrismaNavigation;

import React, { useEffect } from 'react';
import './NotFound.css';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 | PrismaSoft';
  }, []);
  return (
    <div className="not-found-container">
      <div className="not-found">It is good to wander, but not too far.</div>
    </div>
  );
};

export default NotFound;

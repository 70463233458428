export const MenuItems = [
  {
    logo: {
      link: '/',
      image: 'image.png',
      text: 'PrismaSoft',
    },
    navlinks: [
      {
        type: 'link',
        label: 'Why Us',
        link: '/whyus',
        hasDropDown: false,
        dropDownData: [],
      },
      {
        type: 'link',
        label: 'About Us',
        link: '/aboutus',
        hasDropDown: false,
        dropDownData: [],
      },
      {
        type: 'link',
        label: 'Services',
        link: '/services',
        hasDropDown: false,
        dropDownData: [
          {
            type: 'link',
            label: 'settings',
            link: '/settings',
            hasDropDown: false,
            subDropDownData: [],
          },
          {
            type: 'link',
            label: 'Why Us?',
            link: '/whyus',
            hasDropDown: false,
            subDropDownData: [],
          },
          {
            type: 'dropdown',
            label: '',
            link: '',
            hasDropDown: true,
            subDropDownData: [
              {
                type: 'link',
                label: 'settings',
                link: '/settings',
              },
              {
                type: 'link',
                label: 'Why Us?',
                link: '/whyus',
              },
            ],
          },
        ],
      },
      {
        type: 'link',
        label: 'Careers',
        link: '/career',
        hasDropDown: false,
        dropDownData: [],
      },
      {
        type: 'link',
        label: 'Contact',
        link: '/contact',
        hasDropDown: false,
        dropDownData: [],
      },
    ],
    buttons: [
      {
        type: 'solid',
        link: 'www.google.com',
        disabled: false,
      },
      {
        type: 'outlined',
        link: 'www.google.com',
        disabled: false,
      },
    ],
    search: {
      type: 'inline',
      placeHolder: 'Search here...',
      hasIcon: true,
    },
  },
];

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const vacancies = [
  {
    jobId: 1,
    jobTitle: 'Drupal Developer',
    slug: 'drupal-developer',
    skills_and_qualifications: [
      {
        title:
          'Experience building user interfaces for websites and/or web applications.',
      },
      {
        title: 'Extremely fluent with HTML5/XHTML and CSS.',
      },
      {
        title: 'Experience working with PHP, JavaScript, and jQuery.',
      },
      {
        title:
          'Ability to write JavaScript with and without the use of frameworks such as jQuery.',
      },
      {
        title:
          'Experience designing and developing responsive design websites.',
      },
      {
        title:
          'Comfortable working with debugging tools like Firebug, Chrome inspector, etc.',
      },
      {
        title:
          'Ability to understand CSS changes to have consistent style across platforms and browsers.',
      },
      {
        title:
          'Experience developing web solutions in Drupal and PHP implementations in a LAMP environment.',
      },
      {
        title:
          'Advanced site-building experience including deep familiarity with Drupal modules such as CTools, Views, and Panels.',
      },
      {
        title:
          'Experience building responsive websites using Drupal CMS, HTML/HTML5, CSS/CSS3, and JavaScript/jQuery.',
      },
      {
        title:
          'Knowledge of PHP, PHP files and theme functions, and knowledge of the Drupal theme layer.',
      },
      {
        title: 'Through understanding of the Software Development Lifecycle.',
      },
      {
        title: 'Experience creating custom Drupal modules.',
      },
    ],
    job_description: [
      {
        title:
          'Formulate an effective, responsive design and turning it into a working theme.',
      },
      {
        title:
          'Effective, visually appealing, and intuitive implementation of idea.',
      },
      {
        title: 'Design and implement new features and functionality.',
      },
      {
        title:
          'Ensure high-performance and availability, and managing all technical aspects of the CMS.',
      },
    ],
    deadline: new Date('2021-10-05'),
    level: 'All Levels',
    openings: 5,
  },
];

import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Card, Button } from '../../components/UI';
import { packagesData } from '../../storage/packageData';
import { servicesInfo } from '../../storage/services';
import './Pricing.css';

const Pricing = () => {
  let { serviceSlug } = useParams();
  const { pathName } = useLocation();
  let history = useHistory();

  const [curPackage, setCurPackage] = useState(null);
  const [packageInfo, setCurPackageInfo] = useState(null);

  const populateData = useCallback(() => {
    //# get the package info
    let serviceName = servicesInfo.find(
      (service) => service.slug === serviceSlug
    );
    //# redirect on incorrect route
    if (!serviceName) {
      history.push('/404');
    } else {
      serviceName = serviceName.name;
      //# import packages info from a mock file
      const temp = packagesData.filter(
        (service) => service.name === serviceName
      )[0];
      setCurPackageInfo(temp);
      //# set the default selected package
      setCurPackage(temp.serviceData[0].plan);
    }
  }, [setCurPackage, setCurPackageInfo, history, serviceSlug]);

  useEffect(() => {
    document.title = 'Pricing | PrismaSofts';
    window.scrollTo(0, 0);
    populateData();
  }, [pathName, populateData]);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const onClickHandler = function (event) {
    setCurPackage(event.target.innerHTML);
  };

  const pageHeader = packageInfo && (
    <div className="header">
      <div className="pricing-category">{packageInfo.name}</div>
      {packageInfo.description && (
        <div className="pricing-description">{packageInfo.description}</div>
      )}
    </div>
  );

  //* category buttons
  const buttonLabels =
    packageInfo && packageInfo.serviceData.map((item) => item.plan);
  const categoryButtons = packageInfo && (
    <div className="btn-container">
      {buttonLabels.map((packageName, index) => {
        return (
          <Button
            key={packageName + index}
            label={packageName}
            type={
              curPackage === packageName ? 'primary-solid' : 'primary-outline'
            }
            clicked={onClickHandler}
            style={{ margin: '0 10px' }}
          />
        );
      })}
    </div>
  );

  const cardInfo =
    packageInfo &&
    packageInfo.serviceData.filter((item) => item.plan === curPackage);

  const servicesMaker = (serviceInfo) => {
    return serviceInfo.services.map((service, index) => {
      //* change icon based on availability
      const icon = service.available ? (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color="green"
          className="service-icon"
          style={{ width: '1rem', height: '1rem' }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faTimesCircle}
          color="red"
          className="service-icon"
          style={{ width: '1rem', height: '1rem' }}
        />
      );

      return (
        <div key={service.name + index} className="row-flex">
          <div className="icon-container">{icon}</div>
          <p>{service.name}</p>
        </div>
      );
    });
  };

  let cardClassName = `cards`;
  if (curPackage === 'E-Commerce Website') {
    cardClassName += 'ecommerce';
  }

  const planCards = (
    <div className={cardClassName}>
      {cardInfo &&
        cardInfo[0].data.map((info, index) => (
          <Card
            title={info.title}
            titleInfo={info.titleInfo}
            price={info.price}
            pages={info.pages || null}
            key={info + index}
            type="pricing-card"
            cardType=" gray"
            curPackage
            isWebDev
            quote={true}
          >
            {servicesMaker(info)}
          </Card>
        ))}
    </div>
  );

  const getSlugForCurrentPackage = () => {
    const pckg = packageInfo.serviceData.filter(
      (pckg) => pckg.plan === curPackage
    )[0];
    return pckg.slug;
  };

  const noComparison =
    packageInfo && packageInfo.hasOwnProperty('noComparison');

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ delay: 0.3 }}
    >
      <div className="pricing-container">
        {pageHeader}
        {categoryButtons}
        {planCards}
        <div className="learn-more-container">
          {!noComparison && (
            <>
              <p>Want to compare the Plans? </p>
              <Button
                label="Learn More"
                type="success-outline"
                class="learn-more-btn"
                clicked={() =>
                  history.push(`/comparison/${getSlugForCurrentPackage()}`)
                }
              />
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Pricing;

import {
  abhisek,
  rajan,
  aditya,
  samir,
  utsav,
  navraj,
  deepum,
  anita,
  manisha,
  kiran,
  suyog,
  ganesh,
  anish,
  smriti,
  neeleshghi,
  lal,
  kalpana,
  pusparaj,
  dinesh,
  navraj1,
  suraj,
  gauravsharma,
  ajay,
  sushmita,
  neeleshmhz,
  avinash,
  sunil,
  bikramghi,
  dillibha,
  omprakash,
  raheena,
  rajendrasodari,
  rupakrai,
  surajshrestha,
  surakshyt,
  sadish,
  rohanthapa,
  bipinchaudhary,
  srijanarai,
  prayashsth,
  sureshadh,
  sisanmhz,
  /*  alex,
  andrewmcstay,
  andrewmills,
  camren,
  carissa,
  daise,
  emily,
  emma,
  jacob,
  janet,
  kathy,
  lisa,
  patrick,
  philips,
  robin,
  sassy,
  simons, */
} from '../assets/teamImages';

export const teamInfo = [
  {
    name: 'Abhisek Joshi',
    designation: 'MD, Australia',
    motto: 'Necessity is Invention',
    imageLink: abhisek,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Rajan Adhikari',
    designation: 'BD, Australia',
    motto: 'Necessity is Invention',
    imageLink: rajan,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },

  {
    name: 'Aditya Joshi',
    designation: 'Admin/Director',
    motto: 'Work Smart, Not Hard.',
    imageLink: aditya,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Samir Khanal',
    designation: 'Deputy CEO',
    motto: 'Necessity is Invention',
    imageLink: samir,
    links: {
      facebook: 'https://www.facebook.com/samir.khanal.5851',
      instagram: 'https://www.instagram.com/khanalsamir',
      linkedin: 'https://www.linkedin.com/in/samir-khanal-4b9863113',
    },
  },
  {
    name: 'Gaurav Sharma',
    designation: 'Country Head, India',
    motto: 'Necessity is Invention',
    imageLink: gauravsharma,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Pushparaj Joshi',
    designation: 'Sr. Consultant',
    motto: 'Necessity is Invention',
    imageLink: pusparaj,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Lal Airi',
    designation: 'Advisor',
    motto: 'Necessity is Invention',
    imageLink: lal,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Anish Puri',
    designation: 'Marketing Executive',
    motto: 'Necessity is Invention',
    imageLink: anish,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Kalpana Joshi',
    designation: 'Business Consultant',
    motto: 'Necessity is Invention',
    imageLink: kalpana,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  /* {
    name: 'Andrew McStay',
    designation: 'Sr. Project Manager',
    motto: 'Necessity is Invention',
    imageLink: andrewmcstay,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Carissa Nelson',
    designation: 'Sr. Software Developer',
    motto: 'Necessity is Invention',
    imageLink: carissa,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Lisa Welsh',
    designation: 'Sr. Software Developer',
    motto: 'Necessity is Invention',
    imageLink: lisa,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Emily Vaeth',
    designation: 'Sr. Software Developer',
    motto: 'Necessity is Invention',
    imageLink: emily,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Andrew Mills',
    designation: 'Sr. Software Developer',
    motto: 'Necessity is Invention',
    imageLink: andrewmills,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Jacob Rolls',
    designation: 'Sr. Software Developer',
    motto: 'Necessity is Invention',
    imageLink: jacob,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Simons Hayden',
    designation: 'Sr. Researcher',
    motto: 'Necessity is Invention',
    imageLink: simons,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Patrick Bun',
    designation: 'Cyber Security Consultant',
    motto: 'Necessity is Invention',
    imageLink: patrick,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Emma Swiss',
    designation: 'SEO Expert',
    motto: 'Necessity is Invention',
    imageLink: emma,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Daise Hopkin',
    designation: 'SMM Expert',
    motto: 'Necessity is Invention',
    imageLink: daise,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Alex Methews',
    designation: 'Copy Writer/Content Head',
    motto: 'Necessity is Invention',
    imageLink: alex,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Robin Oliz',
    designation: 'Q/A Expert',
    motto: 'Necessity is Invention',
    imageLink: robin,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Philips Williams',
    designation: 'Network Engineer',
    motto: 'Necessity is Invention',
    imageLink: philips,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Sassy Guzman',
    designation: 'Developer',
    motto: 'Necessity is Invention',
    imageLink: sassy,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Janet Kasper',
    designation: 'Developer',
    motto: 'Necessity is Invention',
    imageLink: janet,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  }, */
  {
    name: 'Dinesh Gautam',
    designation: 'Project Manager',
    motto: 'Necessity is Invention',
    imageLink: dinesh,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Sunil Singh',
    designation: 'Product Manager',
    motto: 'Necessity is Invention',
    imageLink: sunil,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Sushmita Twyana',
    designation: 'Executive Assistant',
    motto: 'Necessity is Invention',
    imageLink: sushmita,
    links: {
      facebook: 'https://www.facebook.com/susmita.shth',
      instagram: 'https://www.instagram.com/_sussmeetaa__',
      linkedin: 'https://www.linkedin.com/in/susmita-twana-93918619b',
    },
  },
  {
    name: 'Utsav Baral',
    designation: 'Software Developer',
    motto: 'Necessity is Invention',
    imageLink: utsav,
    links: {
      facebook: 'https://www.facebook.com/utsav.floyd',
      instagram: 'https://www.instagram.com/reddevilutsav',
      linkedin: 'https://www.linkedin.com/in/utsav007',
    },
  },
  {
    name: 'Navraj Khanal',
    designation: 'Software Developer',
    motto: 'Necessity is Invention',
    imageLink: navraj,
    links: {
      facebook: 'https://www.facebook.com/navtheraj',
      instagram: 'https://www.instagram.com/navtheraj',
      linkedin: 'https://www.linkedin.com/in/navrajkhanal',
    },
  },
  {
    name: 'Deepum Poudel',
    designation: 'Software Developer',
    motto: 'Necessity is Invention',
    imageLink: deepum,
    links: {
      facebook: 'https://www.facebook.com/poudel.dee',
      instagram: 'https://www.instagram.com/im.jack.skellington',
      linkedin: 'https://www.linkedin.com/in/dipam-poudel',
    },
  },
  {
    name: 'Ajay Prajapati',
    designation: 'Software Developer',
    motto: 'Necessity is Invention',
    imageLink: ajay,
    links: {
      facebook: 'https://www.facebook.com/ajayprazz',
      instagram: 'https://www.instagram.com/ajayprazz',
      linkedin: 'https://www.linkedin.com/in/ajayprazz',
    },
  },
  // {
  //   name: 'Camren White',
  //   designation: 'Digital Marketing Officer',
  //   motto: 'Necessity is Invention',
  //   imageLink: camren,
  //   links: {
  //     facebook: 'https://www.facebook.com',
  //     instagram: 'https://www.instagram.com',
  //     linkedin: 'https://www.linkedin.com',
  //   },
  // },
  // {
  //   name: 'Kathy Matt',
  //   designation: 'Digital Marketing Officer',
  //   motto: 'Necessity is Invention',
  //   imageLink: kathy,
  //   links: {
  //     facebook: 'https://www.facebook.com',
  //     instagram: 'https://www.instagram.com',
  //     linkedin: 'https://www.linkedin.com',
  //   },
  // },
  {
    name: 'Ganesh J. Rawal',
    designation: 'Graphics Designer',
    motto: 'Necessity is Invention',
    imageLink: ganesh,
    links: {
      facebook: 'https://www.facebook.com/ganeshjrawal',
      instagram: 'https://www.instagram.com/ganeshjrawal',
      linkedin: 'https://www.linkedin.com/in/ganeshrawal',
    },
  },
  {
    name: 'Anita Pulami Magar',
    designation: 'Frontend Designer',
    motto: 'Necessity is Invention',
    imageLink: anita,
    links: {
      facebook: 'https://www.facebook.com/anita.pulami.1',
      instagram: 'https://www.instagram.com/an.ita0',
      linkedin: 'https://www.linkedin.com/in/anita-pulami-magar-008b71202',
    },
  },
  {
    name: 'Neelesh Maharjan',
    designation: 'Jr. Developer',
    motto: 'Necessity is Invention',
    imageLink: neeleshmhz,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Avinash Shrestha',
    designation: 'Jr. Developer',
    motto: 'Necessity is Invention',
    imageLink: avinash,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com/in/avinash-shrestha-745024181',
    },
  },
  {
    name: 'Sadish Gautam',
    designation: 'Jr. Developer',
    motto: 'Necessity is Invention',
    imageLink: sadish,
    links: {
      facebook: 'https://www.facebook.com/sadish.gautam.9',
      instagram: 'https://www.instagram.com/sadish.gautam09',
      linkedin: 'https://www.linkedin.com/in/sãdīsh-gãùtäm-06a9a216b',
    },
  },
  {
    name: 'Nawaraj Adhikari',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: navraj1,
    links: {
      facebook: 'https://www.facebook.com/nawaraj1498',
      instagram: 'https://www.instagram.com/nawaraj_adk',
      linkedin: 'https://www.linkedin.com/in/nawaraj-adhikari-931bb5202',
    },
  },
  {
    name: 'Suraj Chataut',
    designation: 'Research Intern',
    motto: 'Encourage your limits.',
    imageLink: suraj,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },

  {
    name: 'Kiran Poudel',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: kiran,
    links: {
      facebook: 'https://www.facebook.com/amit.poudel.7370',
      instagram: 'https://www.instagram.com/amitam_pdl',
      linkedin: 'https://www.linkedin.com/in/kiran-poudel-9959a5199',
    },
  },
  {
    name: 'Manisha Shrestha',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: manisha,
    links: {
      facebook: 'https://www.facebook.com/maneesa.xrestha',
      instagram: 'https://www.instagram.com/maneesashrestha',
      linkedin: 'https://www.linkedin.com/in/manisha-shrestha-14326a18a',
    },
  },
  {
    name: 'Smriti Shrestha',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: smriti,
    links: {
      facebook: 'https://www.facebook.com/tulashashr',
      instagram: 'https://www.instagram.com/nocent_smriti',
      linkedin: 'https://www.linkedin.com/in/smriti-shrestha-80327018a',
    },
  },
  {
    name: 'Suyog Subedi',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: suyog,
    links: {
      facebook: 'https://www.facebook.com/suyog.b.subedi',
      instagram: 'https://www.instagram.com/suyog_subedi',
      linkedin: 'https://www.linkedin.com/in/suyog-subedi-a9314a1b0',
    },
  },
  {
    name: 'Neelesh Ghimire',
    designation: 'Intern',
    motto: 'Necessity is Invention',
    imageLink: neeleshghi,
    links: {
      facebook: 'https://www.facebook.com/nilesh.ghimire.71',
      instagram: 'https://www.instagram.com/nileshghimire',
      linkedin: 'https://www.linkedin.com/in/nilesh-ghimire-b5aa2a199',
    },
  },
  {
    name: 'Bikram Ghimire',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: bikramghi,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Dilli Bhandari',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: dillibha,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Om Prakash Upadhyaya',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: omprakash,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Raheena Maharjan',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: raheena,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Rajendra Sodari',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: rajendrasodari,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Rupak Rai',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: rupakrai,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Suraj Shrestha',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: surajshrestha,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Surakshyt Khatiwada',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: surakshyt,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Rohan Thapa',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: rohanthapa,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Srijana Rai',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: srijanarai,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Bipin Chaudhary',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: bipinchaudhary,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Prayash Shrestha',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: prayashsth,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Suresh Adhikari',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: sureshadh,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
  {
    name: 'Sisan Maharjan',
    designation: 'Software Trainee',
    motto: 'Necessity is Invention',
    imageLink: sisanmhz,
    links: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    },
  },
];

import React from 'react';
import './OurClients.css';
import ciscoLogo from '../../../assets/logos/cisco.png';
import linkedInLogo from '../../../assets/logos/linkedin.png';
import infodriveLogo from '../../../assets/logos/infodrive.png';
import siemensLogo from '../../../assets/logos/siemens.png';
import unacademyLogo from '../../../assets/logos/unacademy.png';

function OurClients() {
  return (
    <div className="clients-container">
      <div className="client-information">
        <div className="headline">
          <h1>Our Top Clients</h1>
        </div>
        <div className="clients-container">
          <div className="client">
            <img src={ciscoLogo} alt="" />
          </div>
          <div className="client">
            <img src={linkedInLogo} alt="" />
          </div>
          <div className="client">
            <img src={infodriveLogo} alt="" />
          </div>
          <div className="client">
            <img src={siemensLogo} alt="" />
          </div>
          <div className="client">
            <img src={unacademyLogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClients;

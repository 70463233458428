import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import './ContactForm.css';
import { Form, Input, Select } from 'antd';

const ContactForm = () => {
  const [form] = Form.useForm();
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(event);
    let data = {
      name: event.fullname,
      email: event.email,
      interest: event.interest,
      message: event.message,
    };
    let apiData = JSON.stringify(data);
    form.resetFields();

    // // post to api here...
    axios({
      method: 'post',
      url: 'https://prismamessage.herokuapp.com/',
      data: apiData,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        toast.success('Message submitted successfully.');
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div className="form-container">
      <h1>Get in touch</h1>
      <Form
        form={form}
        size="large"
        className="contact-form"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: 'Please enter your Full name!',
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          name="interest"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="What are you interested in?">
            <Select.Option value="software_development">
              Software Development
            </Select.Option>
            <Select.Option value="web_development">
              Web Development
            </Select.Option>
            <Select.Option value="digital_marketing">
              Digital Marketing
            </Select.Option>
            <Select.Option value="engineering_consulting">
              Engineering Consulting
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your Email!',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[
            {
              message: 'Please write your message!',
            },
          ]}
        >
          <Input.TextArea rows={7} placeholder="Message..." />
        </Form.Item>
        <Form.Item>
          <button type="primary" htmlType="submit" className="cta-contact-us">
            Contact
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;

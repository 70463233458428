export const packagesData = [
  {
    name: 'Web Development',
    description: `Give your website an amazing UI & conversion potential with our customized Web design and Development Packages! Prisma Softs Pty Ltd remember the name ! We are best in business. Your website is more than just a collection of pretty images and well-worded keyword filler. It’s your online presence, the hub of your digital existence, the very storefront of the future. If you’re going to be online, then you need a proper website – one built by professionals who are passionate about what they do, and offer
  you comprehensive web design packages prices in Australia, India & Nepal that leave nothing out.`,
    serviceData: [
      {
        plan: 'CMS Website',
        slug: 'cms-website',
        data: [
          {
            title: 'Basic',
            titleInfo: 'For startup businesses.',
            services: [
              { name: 'Responsive Design', available: true },
              {
                name: 'CMS',
                available: true,
              },
              { name: 'Blog', available: true },
              { name: 'Newsletter', available: true },
              {
                name: 'Testimonials',
                available: true,
              },
              {
                name: 'Brochure Management',
                available: true,
              },
              {
                name: 'Jobs Management',
                available: true,
              },
              { name: 'Google Analytics', available: true },
            ],
            price: '550$/Mo.',
            pages: '5',
          },
          {
            title: 'Standard',
            titleInfo: 'For the medium businesses.',
            services: [
              { name: 'Responsive Design', available: true },
              {
                name: 'CMS',
                available: true,
              },
              { name: 'Blog', available: true },
              { name: 'Newsletter', available: true },
              {
                name: 'Testimonials',
                available: true,
              },
              {
                name: 'Brochure Management',
                available: true,
              },
              {
                name: 'Jobs Management',
                available: true,
              },
              { name: 'Google Analytics', available: true },
            ],
            price: '750$/Mo.',
            pages: '10',
          },
          {
            title: 'Advanced',
            titleInfo: 'For the large businesses.',
            services: [
              { name: 'Responsive Design', available: true },
              {
                name: 'CMS',
                available: true,
              },
              { name: 'Blog', available: true },
              { name: 'Newsletter', available: true },
              {
                name: 'Testimonials',
                available: true,
              },
              {
                name: 'Brochure Management',
                available: true,
              },
              {
                name: 'Jobs Management',
                available: true,
              },
              { name: 'Google Analytics', available: true },
            ],
            price: '950$/Mo.',
            pages: '15',
          },
        ],
      },
      {
        plan: 'E-Commerce Website',
        slug: 'e-commerce-website',
        data: [
          {
            title: 'WordPress',
            services: [
              { name: 'Custom Layout', available: true },
              {
                name: 'Search',
                available: true,
              },
              { name: 'My Account', available: true },
              { name: 'Shopping Cart', available: true },
              {
                name: 'Product Listing',
                available: true,
              },
              {
                name: 'Coupon',
                available: true,
              },
              {
                name: 'Newsletter',
                available: true,
              },
              { name: 'SiteMap', available: true },
            ],
            price: '1200$/Mo.',
          },
          {
            title: 'OpenCart',
            services: [
              { name: 'Custom Layout', available: true },
              {
                name: 'Search',
                available: true,
              },
              { name: 'My Account', available: true },
              { name: 'Shopping Cart', available: true },
              {
                name: 'Product Listing',
                available: true,
              },
              {
                name: 'Coupon',
                available: true,
              },
              {
                name: 'Newsletter',
                available: true,
              },
              { name: 'SiteMap', available: true },
            ],
            price: '1500$/Mo.',
          },
          {
            title: 'JavaScript',
            services: [
              { name: 'Custom Layout', available: true },
              {
                name: 'Search',
                available: true,
              },
              { name: 'My Account', available: true },
              { name: 'Shopping Cart', available: true },
              {
                name: 'Product Listing',
                available: true,
              },
              {
                name: 'Coupon',
                available: true,
              },
              {
                name: 'Newsletter',
                available: true,
              },
              { name: 'SiteMap', available: true },
            ],
            price: '2000$/Mo.',
          },
          {
            title: 'Custom',
            services: [
              { name: 'Custom Layout', available: true },
              {
                name: 'Search',
                available: true,
              },
              { name: 'My Account', available: true },
              { name: 'Shopping Cart', available: true },
              {
                name: 'Product Listing',
                available: true,
              },
              {
                name: 'Coupon',
                available: true,
              },
              {
                name: 'Newsletter',
                available: true,
              },
              { name: 'SiteMap', available: true },
            ],
            price: '2599$/Mo.',
          },
        ],
      },
    ],
  },
  {
    name: 'Digital Marketing',
    description: `Digital marketing plays a major role in boosting the traffic as well as rank of the business page. It is
necessary to have a unique image and digital marketing support in earning a specific web presence.
With our customized digital marketing packages & Pricing it is very easy to earn a unique online pres-
ence.
Growing in your business with the support of our expert professional team which is dedicated in serving
according to the requirements is not much complicated as we follow proven strategies. We make sure
that the website earns quality improvement in the search engine traffic and increases the sales as well
as revenue.
With affordable branding packages in Australia, India & Nepal, we offer amazing solutions which are
customized according to the business website needs. With the successful online marketing services we
offer you can make your page draw quality visitors within no time`,
    serviceData: [
      {
        plan: 'Digital Marketing Packages',
        slug: 'digital-marketing-packages',
        data: [
          {
            title: 'Starter',
            titleInfo: 'For startup businesses.',
            services: [
              { name: 'Canonicalization', available: true },
              {
                name: 'Content',
                available: true,
              },
              { name: 'Reputation Mgmt. ', available: true },
              { name: 'Keyword Mgmt.', available: true },
              {
                name: 'Conversion Tracking',
                available: true,
              },
              {
                name: 'Campaign Mgmt.',
                available: true,
              },
              {
                name: 'Blog Posts',
                available: true,
              },
              { name: 'Ads', available: true },
            ],
            price: '2500$/Mo.',
          },
          {
            title: 'Booster',
            titleInfo: 'For medium businesses.',
            services: [
              { name: 'Canonicalization', available: true },
              {
                name: 'Content',
                available: true,
              },
              { name: 'Reputation Mgmt. ', available: true },
              { name: 'Keyword Mgmt.', available: true },
              {
                name: 'Conversion Tracking',
                available: true,
              },
              {
                name: 'Campaign Mgmt.',
                available: true,
              },
              {
                name: 'Blog Posts',
                available: true,
              },
              { name: 'Ads', available: true },
            ],
            price: '4000$/Mo.',
          },
          {
            title: 'Premium',
            titleInfo: 'For large businesses.',
            services: [
              { name: 'Canonicalization', available: true },
              {
                name: 'Content',
                available: true,
              },
              { name: 'Reputation Mgmt. ', available: true },
              { name: 'Keyword Mgmt.', available: true },
              {
                name: 'Conversion Tracking',
                available: true,
              },
              {
                name: 'Campaign Mgmt.',
                available: true,
              },
              {
                name: 'Blog Posts',
                available: true,
              },
              { name: 'Ads', available: true },
            ],
            price: '5000$/Mo.',
          },
        ],
      },
    ],
  },
  {
    name: 'Engineering Consulting',
    description:
      'PrismaSofts Pty. Ltd. has been catering engineering consulting packages to customers to aid in a variety of ways including process management, idea organization, product design, fabrication, branding, and marketing. Explore our consulting options to gain more insights.',
    noComparison: true,
    serviceData: [
      {
        plan: 'Engineering Consulting Packages',
        slug: 'engineering-consulting',
        data: [
          {
            title: 'Basic',
            titleInfo: 'For Growing Businesses.',
            services: [
              { name: 'Process Management', available: true },
              {
                name: 'Idea Organization',
                available: true,
              },
              { name: 'Product Design', available: true },
              { name: 'Fabrication', available: true },
              {
                name: 'Branding',
                available: false,
              },
              {
                name: 'Marketing',
                available: true,
              },
              {
                name: 'Supply Mgmt.',
                available: false,
              },
            ],
            price: '2500$/Mo.',
          },
          {
            title: 'Premium',
            titleInfo: 'For Medium/Large Businesses.',
            services: [
              { name: 'Process Management', available: true },
              {
                name: 'Idea Organization',
                available: true,
              },
              { name: 'Product Design', available: true },
              { name: 'Fabrication', available: true },
              {
                name: 'Branding',
                available: true,
              },
              {
                name: 'Marketing',
                available: true,
              },
              {
                name: 'Supply Mgmt.',
                available: true,
              },
            ],
            price: '5000$/Mo.',
          },
        ],
      },
    ],
  },
  {
    name: 'Mobile Application Development',
    description: `Prisma Softs Pty Ltd is a leading company for highly affordable mobile development packages. We use all platforms to develop user friendly Apps for all businesses. In today's digital world you must embrace all the new and emerging technologies to stay ahead of your competition. The use of mobile has drastically in­creased and it is estimated that more than 80% of the people use their mobile phone to search the Inter­net. That is why mobile applications are in high demand, and all business owners must seriously consider taking advantage of this amazing technology to improve their bottom line. 
    We provide our packages for android as well as IOS operating systems. We provide quality services and packages which will suit any budget. These packages comprise of custom Hybrid Mobile Apps using vari­ous kinds of platforms like android, IOS, windows, etc. We deal in services such as Ecommerce, education, real estate, retail business, etc. 
    Our main focus is to build applications which should be customer centric and are user friendly. We have an extensive amount of experience in mobile app development. If you are looking for reliability and creativity then we can provide you the best solution to meet your specific requirements. `,
    noComparison: true,
    serviceData: [
      {
        plan: 'Mobile Application Packages',
        slug: 'mobile-app-packages',
        data: [
          {
            title: 'Starter',
            titleInfo: 'For Startups.',
            services: [
              { name: 'Image Gallery', available: true },
              {
                name: 'Call To Phone Number',
                available: false,
              },
              { name: 'Services/Product Display', available: false },
              { name: 'Easy to Manage CMS', available: false },
              {
                name: 'Push Notification Offers',
                available: false,
              },
              {
                name: 'SMS Module',
                available: false,
              },
              {
                name: 'Custom Design',
                available: false,
              },
              { name: 'Free 3 Months Support', available: true },
            ],
            price: '999$/Mo.',
          },
          {
            title: 'Start',
            titleInfo: 'For Medium Businesses.',
            services: [
              { name: 'Image Gallery', available: true },
              {
                name: 'Call To Phone Number',
                available: true,
              },
              { name: 'Services/Product Display', available: true },
              { name: 'Easy to Manage CMS', available: true },
              {
                name: 'Push Notification Offers',
                available: true,
              },
              {
                name: 'SMS Module',
                available: false,
              },
              {
                name: 'Custom Design',
                available: true,
              },
              { name: 'Free 3 Months Support', available: true },
            ],
            price: '1499$/Mo.',
          },
          {
            title: 'Gold',
            titleInfo: 'For Large Businesses.',
            services: [
              { name: 'Image Gallery', available: true },
              {
                name: 'Call To Phone Number',
                available: true,
              },
              { name: 'Services/Product Display', available: true },
              { name: 'Easy to Manage CMS', available: true },
              {
                name: 'Push Notification Offers',
                available: true,
              },
              {
                name: 'SMS Module',
                available: true,
              },
              {
                name: 'Custom Design',
                available: true,
              },
              { name: 'Free 3 Months Support', available: true },
            ],
            price: '1999$/Mo.',
          },
        ],
      },
    ],
  },
];

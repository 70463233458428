import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Button.css';

const Button = ({ label, type, clicked, style, hasIcon }) => {
  let icon = null;
  if (hasIcon) {
    icon = (
      <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: '10px' }} />
    );
  }

  return (
    <div className={`button ${type}`} onClick={clicked} style={style}>
      {label}
      {icon}
    </div>
  );
};

export default Button;
